.warningCard {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background-color: rgb(225, 5, 0);
  color: white;
  font-size: 18px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  width: 40vw;
  border-radius: 10px;
  position: relative;
}
.warningCard::before {
  content: '';
  width: 20px;
  height: 70%;
  position: absolute;
  top: 0;
  left: -5%;
  background-color: rgb(225, 5, 0);
}
.warningCard::after {
  content: '';
  width: 20px;
  height: 20%;
  position: absolute;
  bottom: 0;
  left: -5%;
  background-color: rgb(225, 5, 0);
  border-radius: 100%;
}
.closeButtonWrapper {
  position: absolute;
  top: 0;
  right: 0;
  margin: 5px;
  cursor: pointer;
}
