.span {
  display: inline-block;
  margin: 5px;
  padding: 0.1rem 1.1rem;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 92px;
  text-align: center;
}
