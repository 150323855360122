.step4Wrapper {
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(208, 216, 221);
}
.fixedBlock {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(207, 215, 220);
  z-index: 100;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step4Content {
  /* margin-top: 22px; */
  width: 1450px;
  margin: 0 auto;
}
.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 54px;
}
.frequencyWrapper {
  font-size: 1rem;
  line-height: 1.5;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 300px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 54px;
  line-height: 1;
  position: relative;
  background-color: white;
}
.frequencyWrapper::before {
  content: '';
  position: absolute;
  width: 320px;
  height: 5px;
  left: 0;
  top: 51px;
  background-color: rgb(53, 102, 134);
}
.universeWrapper {
  font-size: 1rem;
  line-height: 1.5;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 300px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 54px;
  position: relative;
  background-color: white;
}
.universeWrapper::before {
  content: '';
  position: absolute;
  width: 320px;
  height: 5px;
  left: 0;
  top: 51px;
  background-color: rgb(53, 102, 134);
}
.leftPartWrapper {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
}
.chartTitle {
  margin: 0;
  text-align: start;
}
.chartsWrapper {
  display: flex;
  flex-direction: column;
  width: 1444px;
  /* margin-top: 210px; */
  margin: 210px auto;
  background-color: rgb(207, 215, 220);
}
.twoChartsWrapper {
  display: flex;
  justify-content: space-between;
}
.oneChartWrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  width: 48%;
  padding: 5px;
  position: relative;
  background-color: white;
  margin-bottom: 20px;
}
.oneChartWrapper::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 10px;
  left: 0;
  top: 97%;
  background-color: rgb(53, 102, 134);
}
.chooseMainMediaBlockWrapper {
  display: flex;
}
.toggleSwitchWrapper {
  display: flex;
  justify-content: space-between;
}
.rightPartWrapper {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
