.step2Wrapper {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.inputParametersBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.audienceBlock {
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 20px;
}
.audienceBlockContent {
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  top: 10%;
  padding-top: 10px;
  margin-top: 5px;
  width: 700px;
}
.audioDownloadBlock {
  display: flex;
}
.settingsBlock {
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 20px;
}
.settingsBlockContent {
  background-color: white;
  display: flex;
  width: 900px;
  justify-content: space-between;
  position: absolute;
  top: 10%;
  padding-top: 10px;
  margin-top: 35.5px;
}
.iconWrapper {
  position: relative;
  width: 450px;
  height: 100px;
  border: 2px solid rgb(166, 166, 166);
  border-radius: 30px;
}
.iconWrapperBottom {
  position: relative;
  width: 450px;
  height: 100px;
  border: 2px solid rgb(166, 166, 166);
  border-radius: 30px;
  margin-right: 100px;
}
.iconWrapper_tv {
  position: relative;
  width: 280px;
  height: 100px;
  border: 2px solid rgb(166, 166, 166);
  border-radius: 30px;
  margin-top: -70px;
  margin-left: 320px;
  background-color: white;
}
.icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(166, 166, 166);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: -27%;
  left: 45%;
}
.icon_bottom {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(166, 166, 166);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: -27%;
  left: 55%;
}
.icon_Geo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(166, 166, 166);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: 4%;
  left: 0%;
}
.step2Content {
  min-width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
