.bar {
  background-color: 'white';
  color: 'black';
  width: '100%';
  overflow: 'hidden';
  height: 84px;
  position: relative;
}

.barContent {
  display: flex;
  justify-content: flex-end;
  height: 84px;
}
.logo {
  margin-right: 30px;
  margin-left: 30px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  font-size: 3rem;
  font-weight: 500;
}
.logo span {
  /* color: #d32f2f; */
  color: rgb(225, 5, 0);
}

.tabbedBlock {
  margin-right: 30px;
  margin-left: 30px;
  display: flex;
  align-items: flex-end;
}
.headerButton {
  height: 44px;
  width: 200px;
  border: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 120%;
  z-index: 1100;
  background-color: #d1e2ed;
}
.active {
  background-color: #74a7c8;
  height: 54px;
  color: white;
  font-size: 130%;
}
.delimiterWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: -2%;
  right: 0;
}
