.span {
  display: inline-block;
  margin: 5px;
  padding: 0.1rem 1.1rem;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 92px;
  text-align: center;
}
.input[type='number']:focus {
  background-color: #fff;
  border-color: #e99898;
  border-radius: 5px;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  text-align: center;
  width: 92px;
  padding: 5px;
  margin: 5px;
  padding: 0.1rem 1.1rem;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.5;
}

.spanCpp {
  display: inline-block;
  margin: 5px;
  padding: 0.1rem 1.1rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 90px;
  text-align: center;
}
.inputCpp[type='number']:focus {
  background-color: #fff;
  border-color: #e99898;
  border-radius: 5px;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  text-align: center;
  width: 90px;
  padding: 5px;
  margin: 5px;
  padding: 0.1rem 1.1rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
}
.spanPopulationCityX {
  display: block;
  padding: 5px;
  margin: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 100px;
  text-align: center;
}
.inputPopulationCityX {
  display: block;
  padding: 5px;
  margin: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 100px;
  text-align: center;
}
.inputPopulationCityX[type='number']:focus {
  display: block;
  background-color: #fff;
  border-color: #e99898;
  border-radius: 100px;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  text-align: center;
  width: 100px;
  padding: 5px;
  margin: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.spanBudget {
  display: block;
  padding: 5px;
  margin: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 150px;
}
.inputBudget {
  display: block;
  padding: 5px;
  margin: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 150px;
}
.inputBudget[type='number']:focus {
  color: #495057;
  background-color: #fff;
  border-color: #e99898;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
}
.inputWrapper {
  position: relative;
}
.blockTitle {
  color: #495057;
  font-weight: 500;
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: 0;
  left: 15px;
}
.spanTotalOptBudget {
  display: block;
  padding: 5px;
  margin: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 440px;
  text-align: center;
}
.inputTotalOptBudget {
  display: block;
  padding: 5px;
  margin: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 440px;
  text-align: center;
}
.inputTotalOptBudget[type='number']:focus {
  color: #495057;
  background-color: #fff;
  border-color: #e99898;
  border-radius: 5px;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  text-align: center;
}
.spanOptBudgetLimits {
  display: block;
  padding: 5px;
  margin: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 150px;
  min-height: 25px;
}
.inputOptBudgetLimits {
  display: block;
  padding: 5px;
  margin: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 150px;
}
.inputOptBudgetLimits[type='number']:focus {
  color: #495057;
  background-color: #fff;
  border-color: #e99898;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
}
