.grayFormButton {
  background-color: rgb(231, 231, 240);
  min-height: 35px;
  min-width: 70px;
  border: none;
  color: black;
  white-space: pre;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-weight: bold;
}
.grayFormButton:hover {
  background-color: rgb(200, 200, 209);
  border-color: rgb(200, 200, 209);
}

.redFormButton {
  background-color: rgb(225, 5, 0);
  min-height: 35px;
  min-width: 70px;
  border: none;
  color: white;
  white-space: pre;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-weight: bold;
}
.redFormButton:hover {
  background-color: #ab2424;
  border-color: #ab2424;
}
