.startButton {
  background-color: white;
  min-height: 130px;
  min-width: 300px;
  border: none;
  color: black;
  font-size: 150%;
  white-space: pre;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.startButton:hover {
  background-color: rgb(200, 200, 209);
  border-color: rgb(200, 200, 209);
}
.startButton:disabled {
  background-color: rgb(237, 237, 238);
  border-color: rgb(237, 237, 238);
}
.title {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  padding: 10px;
}
.redTitle {
  color: rgb(225, 5, 0);
}
.icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #a2c4da;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
