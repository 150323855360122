.optButton {
  background-color: rgb(225, 5, 0);
  min-height: 30px;
  min-width: 150px;
  border: none;
  color: white;
  border-radius: 12px;
  padding: 5px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.optButton:hover {
  background-color: #ab2424;
  border-color: #a02222;
}
.optButton:disabled {
  background-color: #c4c4c4;
  border-color: #c4c4c4;
}
