.sign {
  width: 500px;
  height: 300px;
  position: absolute;
  top: 5%;
  z-index: 1000;
  left: 35%;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 70%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.form {
  width: 450px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.formButtonsWrapper {
  width: 450px;
  display: flex;
  justify-content: flex-end;
}
.formInfoTextWrapper {
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
}
