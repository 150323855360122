.wrapper {
  display: flex;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  padding: 3px;
  min-width: 40%;
  margin-right: 50px;
  margin-left: 50px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}
.question {
  color: #d32f2f;
  font-style: italic;
  padding: 10px;
}
.answer {
  padding: 10px;
}
