.howItWorksBlock {
  margin-top: 50px;
  margin-bottom: 30xp;
}
.steps {
  display: flex;
  justify-content: space-evenly;
}
.startNewProjectBtnWrapper {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.link {
  text-decoration: none;
}
