.downloadButton {
  background-color: white;
  min-height: 40px;
  min-width: 100px;
  border: none;
  color: rgb(200, 200, 209);
  font-size: 130%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
}
.downloadButton:hover {
  color: rgb(166, 166, 166);
}
.downloadButton:disabled {
  color: rgb(237, 237, 238);
}
