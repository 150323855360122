.pageBarContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 1450px;
  font-size: 2.4em;
  font-weight: bold;
  margin: 0 auto;
}
.pageHeaderNumber {
  font-size: 1.2em;
  color: rgb(225, 5, 0);
  margin-right: 20px;
  text-shadow: 2px 2px 3px rgb(166, 166, 166); /* Параметры тени */
}
.titleWrapper {
  margin-left: 150px;
}
.redText {
  color: rgb(225, 5, 0);
}
