.scrollUp {
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid black;
  position: fixed;
  z-index: 9999;
  right: 30px;
  bottom: 30px;
  opacity: 0;
  font-size: 25px;
  transition: opacity 0.5s;
  display: flex;
  justify-content: center;
}
.isVisible {
  opacity: 1;
}
