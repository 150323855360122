.toggle_switchWrapper {
  display: flex;
}
.toggle_switch {
  position: relative;
  width: 75px;

  display: inline-block;
  vertical-align: middle;
  text-align: left;
}
.toggle_switch_checkbox {
  display: none;
}
.toggle_switch_label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  margin: 0;
  height: 25px;
  width: 75px;
}
.toggle_switch_inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  /* transition: margin 0.3s ease-in 0s; */
}
.toggle_switch_inner::before {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
  content: '';
  text-transform: uppercase;
  padding-left: 10px;
  background-color: #d32f2f;
}
.toggle_switch_inner::after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
  content: '';
  text-transform: uppercase;
  padding-right: 10px;
  background-color: #bbb;
  text-align: right;
}
.toggle_switch_switch {
  display: block;
  width: 20px;
  height: 20px;
  margin: 3px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.toggle_switch_inner_unchecked {
  margin-left: 0;
}
.toggle_switch_switch_unchecked {
  right: 49px;
}
.muted {
  color: #bbb;
  margin-left: 5px;
  margin-right: 5px;
}
.label {
  margin-left: 5px;
  margin-right: 5px;
}
