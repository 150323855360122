.delimiterWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 70px;
}
.delimiterTitle {
  font-weight: bold;
  font-size: 1.8rem;
  position: absolute;
  top: 30px;
  left: 10%;
}
.delimiterImgs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
