.input[type='number'] {
  display: block;
  padding: 5px;
  margin: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 100px;
  text-align: center;
}
.input[type='number']:focus {
  color: #495057;
  background-color: #fff;
  border-color: #e99898;
  border-radius: 100px;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  text-align: center;
}
.inputWrapper {
  position: relative;
}
.blockTitle {
  color: #495057;
  font-weight: 500;
  font-size: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  position: absolute;
  top: 0;
  left: 15px;
}

.input_Optimization {
  display: block;
  padding: 5px;
  margin: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 440px;
  text-align: center;
}
.input_Optimization[type='number']:focus {
  color: #495057;
  background-color: #fff;
  border-color: #e99898;
  border-radius: 5px;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  text-align: center;
}
.input_Optimization_Table {
  display: block;
  padding: 5px;
  margin: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
  width: 150px;
}
.input_Optimization_Table[type='number']:focus {
  color: #495057;
  background-color: #fff;
  border-color: #e99898;

  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 40%);
}
